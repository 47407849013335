<app-layout></app-layout>

<div class="content">
  <div style="margin-bottom: 30px; display: flex; align-items: center;">
    <label for="filter" style="color: black; font-size: 18px; font-weight: bold; margin-right: 10px;">Filter By:</label>
    <select id="filter" [(ngModel)]="selectedFilter" (change)="onChangeCategory($event.target.value)"  class="dropdownStyles" style="cursor: pointer;">
      <option value="daily">Daily</option>
      <option value="weekly">Weekly</option>
      <option value="monthly">Monthly</option>
      <option value="yearly">Annually</option>
    </select>
  </div>
  
  
  

 <div class="container">
  <div class="card-wrapper">
    <div class="clickable-div registered-customers" routerLink="/manage-profile/list" style="width: 300px; text-align: center;">
      <img class="card-content" src="../../../assets/images/custiomer.png" alt="Devices Icon">
      <h3>Registered Customers</h3>
      <p>{{registeredUsers}}</p>
    </div>

    <div 
    class="clickable-div revenue-generated card-style" 
    style="cursor: pointer; width: 300px; text-align: center;" 
    (click)="openInNewTab('https://appstoreconnect.apple.com/trends/insights?pageid=6')">
    <img class="card-content" src="../../../assets/images/appstore.png" alt="Devices Icon">
    <h3>
      <span>Revenue Generated</span><br>
      <span>By AppStore</span>
    </h3>
  </div>
  
  

  <div 
  class="clickable-div revenue-generated" 
  style="cursor: pointer; width: 300px; text-align: center;" 
  (click)="openInNewTab('https://play.google.com/console/u/0/developers/6581959376774739451/app/4976152002404730908/reporting/finance/revenue')">
  <img class="card-content" src="../../../assets/images/playstore new8.png" alt="Devices Icon">
  <h3>
    <span>Revenue Generated</span><br>
    <span>By PlayStore</span>
  </h3>
</div>

  </div>
</div>

  
  
</div>


