import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/auth/services/auth.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  userEmail: string;
  template: TemplateRef<any>;
  modalRef: any;
  isSettingsSubMenuOpen: boolean = false;
  isAnalyticsSubMenuOpen: boolean = false;
  isChangePasswordSelected: boolean = false;
  isUpdateProfileSelected: boolean = false;
  currentUser: any;
  mainEmailId: any;

  constructor(private modalService: BsModalService, private authService: AuthService, public router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.checkCurrentRoute();
    });
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
    this.mainEmailId = this.currentUser?.email;
    this.userEmail = 'amni@yopmail.com';
    this.checkCurrentRoute();
  }

  checkCurrentRoute() {
    const currentUrl = this.router.url;
    if (currentUrl.includes('edit-profile') || currentUrl.includes('manage-profile')) {
      this.isSettingsSubMenuOpen = true;
    } else {
      this.isSettingsSubMenuOpen = false;
    }
  }

  ngDoCheck() {
    if (window.location.pathname.indexOf('coupon') != -1) {
      document.getElementById('couponmenu').scrollIntoView();
    } else if (window.location.pathname.indexOf('coordinates') != -1) {
      document.getElementById('Coordinates').scrollIntoView();
    } else if (window.location.pathname.indexOf('notifications') != -1) {
      document.getElementById('notifications').scrollIntoView();
    } else if (window.location.pathname.indexOf('event-sponsors') != -1) {
      document.getElementById('Sponsors').scrollIntoView();
    } else if (window.location.pathname.indexOf('treasure') != -1) {
      document.getElementById('Treasure').scrollIntoView();
    } else if (window.location.pathname.indexOf('more-sections') != -1) {
      document.getElementById('More-Section').scrollIntoView();
    } else if (window.location.pathname.indexOf('home-partners') != -1) {
      document.getElementById('home-partners').scrollIntoView();
    }
  }

  toggleSubMenu(submenu: string) {
    if (submenu === 'settings') {
      this.isSettingsSubMenuOpen = !this.isSettingsSubMenuOpen;
      this.isAnalyticsSubMenuOpen = false;
    } else if (submenu === 'analytics') {
      this.isAnalyticsSubMenuOpen = !this.isAnalyticsSubMenuOpen;
      this.isSettingsSubMenuOpen = false;
    }
  }

  highlightChangePassword(event: Event) {
    event.stopPropagation();
    this.isChangePasswordSelected = true;
    this.isUpdateProfileSelected = false;
  }

  highlightUpdateProfile(event: Event) {
    event.stopPropagation();
    this.isChangePasswordSelected = false;
    this.isUpdateProfileSelected = true;
  }

  confirm() {
    this.logout();
  }

  cancel() {
    this.modalRef.hide();
  }

  logout() {
    this.modalRef.hide();
    localStorage.removeItem("currentUser");
    this.router.navigateByUrl("/auth/login");
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
}
