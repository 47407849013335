import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ViewsComponent } from "./views/views.component";
import { AuthGuard } from "./_helpers/auth.guard.service";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },

  { path: "", redirectTo: "auth", pathMatch: "full" },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  }, 
  {
    path: "",
    component: ViewsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "change-password", component: EditProfileComponent },

      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },

     
      {
        path: "analytics-dashboard",
        loadChildren: () =>
          import("../app/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          )
      },

      

      {
        path: "manage-guest",
        loadChildren: () =>
          import("../app/views/manage-guest/manage-guest.module").then(
            (m) => m.ManageGuestModule
          )
      },


      {
        path: "manage-admin",
        loadChildren: () =>
          import("../app/views/manage-sub-admin/manage-sub-admin.module").then(
            (m) => m.ManageSubAdminModule
          )
      },
     

      {
        path: "manage-host",
        loadChildren: () =>
          import("../app/views/manage-host/manage-host.module").then(
            (m) => m.ManageCaregiversModule
          )
      }, 

      {
        path: "manage-bookings",
        loadChildren: () =>
          import("../app/views/manage-bookings/manage-bookings.module").then(
            (m) => m.ManageBookingsModule
          )
      }, 

      {
        path: "manage-vendors",
        loadChildren: () =>
          import("../app/views/manage-vendors/manage-vendors.module").then(
            (m) => m.ManageVendorsModule
          )
      },

      {
        path: "manage-profile",
        loadChildren: () =>
          import("../app/views/manage-view/manage-view.module").then(
            (m) => m.ManageViewModule
          )
      },

      
      {
        path: "manage-payments",
        loadChildren: () =>
          import("../app/views/manage-payments/manage-payments.module").then(
            (m) => m.ManagePaymentsModule
          )
      },

      {
        path: "manage-settings",
        loadChildren: () =>
          import("../app/views/manage-settings/manage-settings.module").then(
            (m) => m.ManageSettingsModule
          )
      },

     
    
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

