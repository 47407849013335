<app-layout></app-layout>
<div class="content">

<div class="skill-tabs d-flex justify-content-between align-items-top">
    <div class="skill-tab-left">
        <div class="table-responsive">
            <table class="table mt-2 custom-table" aria-label="Description of the table" style="border-collapse: collapse;">
                <thead class="table-header" style="background-color: #0E103D;">
                    <tr>
                        <th scope="col" class="header-text" style="border: 1px solid black;width: 100px;">S.No</th>
                        <th scope="col" class="header-text" style="border: 1px solid black;width: 200px;">View Payment Records</th>
                        <th scope="col" class="header-text" style="border: 1px solid black;width: 200px;">Total Payments</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- Each row now includes borders for each cell -->
                    <tr>
                        <td style="border: 1px solid black;">1</td>
                        <td style="border: 1px solid black;">Record 1</td>
                        <td style="border: 1px solid black;">Title1</td>
                    </tr>

                    <tr>
                        <td style="border: 1px solid black;">2</td>
                        <td style="border: 1px solid black;">Record 2</td>
                        <td style="border: 1px solid black;">Title2</td>
                    </tr>

                    <tr>
                        <td style="border: 1px solid black;">3</td>
                        <td style="border: 1px solid black;">Record 3</td>
                        <td style="border: 1px solid black;">Title3</td>
                    </tr>

                    <tr>
                        <td style="border: 1px solid black;">4</td>
                        <td style="border: 1px solid black;">Record 4</td>
                        <td style="border: 1px solid black;">Title4</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</div>

