<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Buddhaword Login</title>
</head>
<body>
  <div class="main-container">
    <!-- <div class="left-panel">
      <img src="../../../../assets/new images/round.png" alt="Buddhaword Logo" class="logo" >
      <img src="../../../../assets/new images/Capital.png" alt="Buddhaword Logo" class="logo" >

    </div> -->

    <div  class="left-panel">
      <div class="image-button" style="margin-left: 304px">
        <img src="../../../../assets/new images/round.png" alt="Round Logo" class="logo">
        <img src="../../../../assets/new images/Capital.png" alt="Capital Logo" class="logo">
      </div>
    </div>
    
    <div class="right-panel">
      <img src="../../../../assets/new images/Welcome Back! (1).png"  class="wel-logo" alt=""/>
      <!-- <img src="../../../../assets/new images/Sign in to your Buddhaword account.png"  class="sign-logo" alt=""/> -->
      <p style="font-family: 'poppins';padding-top: 14px;
    padding-right: 209px;color: black;font-size: 20px;">Sign in to your <strong>BuddhaWord</strong> account</p>

     
      <form class="login-right" [formGroup]="loginForm" (submit)="login()">
        <div  style="margin-top: 20px; width: 100%;">
          <label for="email" style="font-family: Arial, sans-serif; font-size: 14px; color: black;">Email</label>
          <input type="email" id="email" placeholder="Enter Email" formControlName="email"
                 style="width: 150% !important; padding: 15px; margin-top: 5px; border: none; border-radius: 10px; box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); background-color: #efe6db; color: gray; font-size: 14px; font-family: Arial, sans-serif;">
        </div>
        
        <div style="margin-top: 20px; width: 100%; position: relative;">
          <label for="password" style="font-family: Arial, sans-serif; font-size: 14px; color: black;">Password</label>
          <input 
            [type]="hide ? 'password' : 'text'" 
            id="password"  formControlName="password"
            placeholder="Enter Password" 
            style="width: 150% !important; padding: 15px; margin-top: 5px; border: none; border-radius: 10px; box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); background-color: #efe6db; color: gray; font-size: 14px; font-family: Arial, sans-serif; padding-right: 40px;">
          <mat-icon 
            class="placeholder" 
            (click)="myFunction()" 
            style="position: absolute; left: 400px; top: 69%; transform: translateY(-50%); cursor: pointer;">
            {{hide ? 'visibility_off' : 'visibility'}}
          </mat-icon>
        </div>
        
      
        
        
        <a href="#" class="forgot-password" routerLink="/auth/forgot-password" style="padding-top: 20px;margin-left: 141px;">Forgot password?</a>
        <button type="submit" class="btn">Sign In</button>
      </form>
    </div>
  </div>
</body>
</html>
