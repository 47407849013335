
<div class="sidebar" style="background-color: #E2C9AD;border: none;">
  <!-- #E2C9AD

  8F6D45 -->

  
  <div class="image-container">
  <img src="../../../../assets/new images/round.png" alt="Round Logo" class="logo">
  <img src="../../../../assets/new images/Capital.png" alt="Capital Logo" class="logo">
</div>

 

  <div class="header-links" style="margin-top: 50px;">

    <a routerLink="/analytics-dashboard" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1); margin-bottom: 20px;">
      <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/new images/reporting.png" alt="" height="24" width="24" style="filter: brightness(0) invert(1);">
      </span>
      <span class="link-text">Reporting</span>
  </a>
  
   

  <a routerLink="/manage-guest" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
    <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/new images/service category.png" alt="" height="24" width="24">
    </span>
    <span class="link-text">Meditation Categories</span>
</a>

<a routerLink="/manage-host" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/property.png" alt="" height="24" width="24">
  </span>
  <span class="link-text">Manage Audio Files</span>
</a>
<!-- 


<a routerLink="/manage-vendors" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Manage Vendors</span>
</a>

<a routerLink="/manage-admin" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Manage Sub-Admin</span>
</a>

<a routerLink="/manage-bookings" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Service Bookings</span>
</a> -->

<!-- <a routerLink="/manage-profile" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/manage sub-admin.png" alt="" height="24" width="24">
  </span>
  <span class="link-text">Manage View Profile</span>
</a> -->
<!-- 
<a routerLink="/manage-payments" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">View Payments</span>
</a>

<a routerLink="/manage-settings" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Settings</span>
</a>
 -->

 
 <div class="menu-item" (click)="toggleSubMenu('settings')" style="margin-left: 30px;">
  <span class="sidebar-icon">
    <img class="white-icon" src="../../../assets/new images/manage service.png" alt="" height="24" width="24" style="cursor: pointer;">
  </span>
  <span class="link-text">Settings</span>

  <img *ngIf="!isSettingsSubMenuOpen" src="../../../assets/new images/new arrow.png" alt="" class="menu-icon" style="margin-left: 120px; cursor: pointer; height: 13px; width: 12px;" />
  <img *ngIf="isSettingsSubMenuOpen" src="../../../assets/new images/new arrow.png" alt="" class="menu-icon" style="margin-left: 120px; cursor: pointer; height: 13px; width: 12px; transform: rotate(180deg);" />

  <img style="padding-left: 95px; cursor: pointer;" src="../../../assets/icons/arrow.png" alt="" class="arrow" [ngClass]="{ 'active-arrow': isSettingsSubMenuOpen }">
</div>

<div *ngIf="isSettingsSubMenuOpen" id="submenu-settings">
  <a routerLink="/edit-profile" routerLinkActive="active-link" class="submenu-link" (click)="highlightChangePassword($event)">
    <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/change paswordd.png" alt="" height="24" width="24">
    </span>
    <span class="link-text">Change Password</span>
  </a>
  <a routerLink="/manage-profile" routerLinkActive="active-link" class="submenu-link" (click)="highlightUpdateProfile($event)" style="margin-bottom: 15px;">
    <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/update profil.png" alt="" height="24" width="24">
    </span>
    <span class="link-text">View Profile</span>
  </a>
</div>








<a routerLink="/manage-bookings" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">About BuddhaWord</span>
</a>


  
  <!-- <a routerLink="/edit-profile" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
    <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/new images/change paswordd.png" alt="" height="24" width="24">
    </span>
    <span class="link-text">Change Password</span>
  </a>  -->
  
  <a (click)="openModal(template)" routerLinkActive="active-link"  id="logout" style="color: rgba(96, 111, 140, 1);">
      <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/new images/Logout.png" alt="" height="24" width="24" style="cursor: pointer;">
      </span>
      <span class="link-text">Logout</span>
  </a>
   
  
<ng-template #template>

  <div class="modal-body text-center delete-modal align-items-center" style="background-color: #E2C9AD;">
    <!-- <button type="button" class="close close-button-black-border" aria-label="Close" (click)="modalRef.hide()"
    routerLink="/careers/list">
    <span aria-hidden="true" class="logIcon">&times;</span>
</button> -->
      <img  src="../../../assets/images/4753177.jpg" alt="" style="height: 90px;width: 90px;">
      <p style="color: black;">Are you sure you <br>want to logout?</p>
      <div class="modal-btn d-flex justify-content-around">
          <button class="btn btn-red" (click)="cancel()" style="background-color: #8F6D45;">No</button>
          <button class="btn btn-red" (click)="confirm()" style="color: #000;">Yes</button>
      </div>
  </div>
</ng-template>

  </div>
</div>



